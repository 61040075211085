export default {
  company_details: {
    company: 'Bedrijf',
    bank_info: 'Bankgegevens',
    bank_account: 'Bankrekening',
    legal_address: 'Hoofdkantoor',
    legal_form: 'Juridische vorm',
    legal_name: 'Wettelijke naam',
    nace_codes: 'Bedrijfsactiviteit',
    history: 'Activiteitengeschiedenis',
    vat_number: 'BTW-nummer',
    gross_margin: 'Omzet',
    employees: 'Werknemers',
    gain_loss: 'Winst en verlies',
    turnover: 'Eigen vermogen',
    email: 'Hoofde-mailadres'
  },
  title: 'Bedrijven',
  vat_number: 'BTW-nummer',
  legal_name: 'Wettelijke naam',
  legal_address: 'Hoofdkantoor',
  commercial_name: 'Handelsnaam',
  new_company: 'Nieuw bedrijf',
  create_company: 'Bedrijf creëren',
  company_created: 'Bedrijf gecreëerd',
  company_already_exists: 'Dit bedrijf is al eerder gecreëerd',
  company_not_created: 'Er is een fout opgetreden, het bedrijf is niet gecreëerd',
  vat_number_required: 'Het BTW-nummer is vereist',
  vat_number_format: 'Het BTW-nummer moet in het formaat BE0123456789 zijn',
  tabs: {
    contracts: 'Contracten',
    details: 'Details',
    members: 'Leden',
    transfers: 'Overdrachten',
    wins: 'Wins'
  },
  forms: {
    add_member_cta: 'Lid toevoegen',
    add_member_title: 'Lid toevoegen aan het bedrijf',
    user_not_found:
      'De gezochte gebruiker bestaat nog niet. Zorg ervoor dat het e-mailadres correct is om deze gebruiker aan te maken.',
    user_found: 'Hier is het geselecteerde adres',
    selected_user: 'Geselecteerde gebruiker',
    previous_step_cta: 'Ga terug',
    next_step_cta: 'Doorgaan',
    create_member_cta: 'Dit lid opslaan',
    add_roles_title: 'Rollen toewijzen aan lid',
    add_roles_cta: 'Rollen opslaan',
    edit_positions_cta: 'Ledenfuncties bewerken',
    edit_roles_cta: 'Rol van lid wijzigen',
    edit_title_cta: 'Ledentitel bewerken',
    save_cta: 'Opslaan',
    roles: {
      signatory: 'Ondertekenaar',
      unpaid_alert_receiver: 'Beheerder wanbetalingen',
      primary_contact_member: 'Eerste contactpersoon'
    },
    positions: [
      {
        value: 'delegated_administrator',
        label: 'Gedelegeerd bestuurder'
      },
      {
        value: 'administrator',
        label: 'Bestuurder'
      },
      {
        value: 'ceo',
        label: 'CEO'
      },
      {
        value: 'cfo',
        label: 'CFO'
      },
      {
        value: 'accountant',
        label: 'Boekhouder'
      },
      {
        value: 'head_of_administration',
        label: 'Hoofd administratie'
      },
      {
        value: 'head_of_people',
        label: 'Hoofd personeelszaken'
      }
    ]
  }
}
