export default {
  copied_to_clipboad: 'De e-mailtekst is gekopieerd:',
  copy: 'Kopiëren',
  email_to_copy: 'Te verzenden e-mail ✉️:',
  new_termination: '⛔ Nieuwe opzegging',
  new_termination_date_email_content: `Hallo,<br>
<br>
Gelieve de ingangsdatum van contract {policyNumber} te verschuiven naar {policyEndDate}.
<br>De klant {clientFullName} is tot die datum verzekerd bij {oldInsurerName}.
<br>
<br>Alvast bedankt,`,
  new_termination_date_info:
    'Je hebt de opzegging van de klant op een andere datum vastgelegd, dus:<br><br>1. Kopieer de onderstaande e-mailsjabloon en stuur deze per e-mail naar de maatschappij om de datum van het NIEUWE contract van de klant te wijzigen (die je waarschijnlijk al hebt opgezet, omdat dat het proces is om te voorkomen dat de klant niet verzekerd is)<br>2. Maak onmiddellijk een Pipedrive-activiteit aan binnen 3 weken om dan te controleren of de wijziging is doorgevoerd, en pas op dat moment de ingangsdatum van het contract op Ardor aan',
  title: 'Opzeggingen',
  otherInsurer: 'Geen verzekeraar',
  as: 'als',
  represented_by: 'vertegenwoordigd door'
}
