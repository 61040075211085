export default {
  categories: {
    belonging_change: 'Wijziging van risico-object',
    cover_change: 'Wijziging van dekking(en)',
    encoding_correction: 'Ingavecorrectie',
    encoding_correction_client: 'Ingavecorrectie (Klant)',
    encoding_correction_insurer: 'Ingavecorrectie (Verzekeraar)',
    encoding_correction_yago: 'Ingavecorrectie (Yago)',
    encoding_correction_seraphin: 'Ingavecorrectie (Yago)', // to catch cases with legacy-status amendments
    exclusive_driver_clause: 'Toevoeging van exclusieve bestuurdersclausule',
    gross_premium_change: 'Wijziging van de premie',
    other: 'Anders',
    policy_owner_data_change: 'Wijziging contactgegevens',
    premium_change: 'Wijziging van de premie' // to catch cases with legacy-status amendments
  },
  endorsement_signature_warning:
    'Let op: Het is absoluut noodzakelijk om een endorsement te laten ondertekenen om de naleving van de wet en de bescherming van de rechten van de klant te garanderen. Zonder dit ondertekende document kan Yago worden blootgesteld aan boetes en is de klant mogelijk niet naar behoren gedekt in het geval van een claim. Neem contact op met je teamleider voordat je dit vakje uitschakelt',
  contractually_frozen:
    'Deze klant heeft andere contracten, zorg dus dat je voor elk contract dat door de wijziging wordt beïnvloed een aanhangsel registreert.',
  description: 'Beschrijving:',
  description_placeholder:
    'Beschrijf hier in enkele zinnen het doel van het aanhangsel, zodat de klant begrijpt naar welke verandering in zijn contract dit aanhangsel verwijst. Deze tekst wordt weergegeven in de persoonlijke ruimte en in de Informatieplicht; let dus op de nauwkeurigheid en spelling van deze beschrijving.',
  details: {
    description: 'Initiële aanvraag van de klant:',
    elements: {
      alarm_installed: 'Alarm:',
      birthdate: 'Geboortedatum:',
      chassis_number: 'Chassisnummer:',
      city: 'Stad:',
      engine_displacement: 'Cilinderinhoud:',
      entry_into_circulation: '1ste inschrijving:',
      first_name: 'Voornaam:',
      is_company: 'Bedrijf:',
      last_name: 'Achternaam:',
      legal_name: 'Wettelijke naam van het bedrijf:',
      licence_plate: 'Kenteken:',
      model_label: 'Model:',
      motorbike_manufacturer: 'Merk:',
      motorbike_type: 'Type:',
      owner_title: 'Functie binnen het bedrijf',
      pre_tax_price: 'Prijs exclusief BTW:',
      second_hand: 'Tweedehands:',
      street_box: 'Bus:',
      street_name: 'Straat:',
      street_number: 'Nummer:',
      usage: 'Gebruik:',
      vat_number: 'BTW-nummer:',
      zip_code: 'Postcode:'
    },
    new_motorbike_vehicle: 'Nieuw voertuig',
    new_policy_owner: 'Nieuwe polisnemer',
    old_motorbike_vehicle: 'Oud voertuig',
    old_policy_owner: 'Oude polisnemer'
  },
  edit: 'De aanhang wijzigen',
  forms: {
    select_options: {
      categories: [
        {
          label: 'Wijziging contactgegevens',
          value: 'policy_owner_data_change'
        },
        {
          label: 'Wijziging van de premie',
          value: 'gross_premium_change'
        },
        {
          label: 'Ingavecorrectie (Verzekeraar)',
          value: 'encoding_correction_insurer'
        },
        {
          label: 'Ingavecorrectie (Klant)',
          value: 'encoding_correction_client'
        },
        {
          label: 'Ingavecorrectie (Yago)',
          value: 'encoding_correction_yago'
        },
        {
          label: 'Wijziging van risico-object',
          value: 'belonging_change'
        },
        {
          label: 'Wijziging van dekking(en)',
          value: 'cover_change'
        },
        {
          label: 'Anders',
          value: 'other'
        }
      ]
    },
    warning: 'Instructies en voorzorgsmaatregelen (Goed te lezen voor het eerste gebruik van dit formulier)'
  },
  broker_billing_warning:
    'Het contract is in incasso bij YAGO. Als u een wijziging doorvoert die de verzekeringspremie verandert, moet u een nieuwe incasso-factuur opstellen.',
  gross_premium_change_warning:
    'Dit aanhangsel leidt tot een prijswijziging. Het kan daarom niet automatisch worden gevalideerd. Raadpleeg het IT-team om deze situatie te behandelen.',
  instructions:
    'Dit formulier maakt de creatie van een nieuwe aanhangsel of de wijziging van een bestaand aanhangsel mogelijk. Bij het aanmaken van een nieuw aanhangsel zal dit aan de klant per e-mail worden meegedeeld (alleen bij het aanmaken en niet bij het wijzigen van de aanhang). In deze e-mail wordt hij uitgenodigd om zijn aanhang online te ondertekenen. Het door de backoffice operator geüploade document zal worden aangevuld met een automatisch gegenereerde informatieverplichting door het platform. Dit bevat onder meer het nummer van het aanhangsel, de ingangsdatum en de beschrijving. Het is belangrijk op te merken dat een aanhangsel alleen wijzigbaar is zolang deze nog niet door de klant is ondertekend. Bij wijziging is het niet nodig om een bestand opnieuw te uploaden als dit niet is veranderd.',
  modifications_guidance:
    'Een aanhangsel betekent meestal wijzigingen in het contract,<br>de risico-objecten en/of het klantprofiel. Gelieve deze wijzigingen te registreren via<br>de onderstaande knoppen voordat je de aanhang registreert.',
  no_validation_warning:
    'Het aanhangsel is niet gekoppeld aan een Baloise-motor, dus het kan niet automatisch worden gevalideerd. Raadpleeg het IT-team om deze situatie te behandelen.',
  see_amendment: 'Het aanhangsel bekijken',
  title: 'Aanhangsels'
}
