export default {
  cancel: 'Annuler',
  email: 'Email',
  email_required: "L'email est requis",
  first_name: 'Prénom',
  first_name_required: 'Le prénom est requis',
  last_name: 'Nom',
  last_name_required: 'Le nom est requis',
  mobile_phone: 'Téléphone mobile',
  language: 'Langue',
  language_required: 'La langue est requise',
  language_options: {
    french: 'Français',
    dutch: 'Néerlandais'
  },
  new_member: 'Nouveau membre',
  create_member: 'Ajouter un membre',
  member_created: 'Membre ajouté',
  member_already_registered: 'Ce membre existe déjà',
  member_not_created: "Une erreur est survenue, le membre n'a pas pu être ajouté"
}
