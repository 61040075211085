export default {
  bundled_payment_reminders: {
    amount_due: 'Montant dû',
    types: ['Rappel 1', 'Rappel 2', 'Rappel 3', 'Arrêt du plan de paiement']
  },
  bundled_payment_reminders_unpaids_doc:
    'Des questions sur les mails et sms impayés ? Consultez la documentation à ce sujet.',
  bundled_payment_reminders_unpaids_doc_link:
    'https://seraphin.slite.com/api/s/note/4g9DSbAbkAXskUV84AYty1/Impayes-plan-de-paiement',
  doc: 'Des questions sur les mails et sms impayés ? Consultez la documentation à ce sujet.',
  doc_link: 'https://seraphin.slite.com/api/s/note/RkxKwYJJ5PdWQ8bJcwuj57/Impayes',
  edit: {
    title: "Modifier l'impayé"
  },
  help: "Vous trouverez <a href='https://docs.google.com/document/d/1pCnXMefQAFOYBfm61r184F_1jdlos2KDGuTQ9yWcu_s/edit' target='_blank'>ici</a> le descriptif de l'ensemble des messages et action de rappel liés aux impayés.",
  new_bundled_payment_unpaid:
    '💸 Nouvel impayé plan de paiement (seulement possible pour les plans de paiement Baloise)',
  new_unpaid: '💸 Nouvel impayé',
  reminders: {
    cease_and_desist_date: 'Date de mise en demeure',
    edit_unpaid: 'Modifier le montant dû: €{amountDue}',
    message: '<strong>Rappel n°{action} envoyé au client</strong><br>{message}',
    title: 'Envoyer un nouveau rappel',
    types: ['Rappel 1', 'Rappel 2', 'Rappel 3 - Mise en demeure', 'Rappel 4 - Suspension', 'Résiliation']
  },
  table: {
    columns: {
      tags: {
        danger: 'Bientôt résilié',
        success: 'Premiers rappels',
        warning: 'Contrat résilié'
      }
    }
  },
  title: 'Impayés'
}
