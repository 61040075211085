import initialState from '../_helpers/initialState'
import api from '@/services/api_v2'

export default {
  namespaced: true,
  state: {
    ...initialState,
    company: null,
    members: null,
    signatoryMember: null,
    signatoryMemberLoading: false,
    membersLoading: false
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_MEMBERS_LOADING(state, payload) {
      state.membersLoading = payload
    },
    SET_SIGNATORY_MEMBER_LOADING(state, payload) {
      state.signatoryMemberLoading = payload
    },
    SET_COMPANY(state, payload) {
      state.company = payload
    },
    SET_MEMBERS(state, payload) {
      state.members = payload
    },
    SET_SIGNATORY_MEMBER(state, payload) {
      state.signatoryMember = payload
    },
    SET_COMPANY_EMAIL(state, data) {
      state.company.email = data
    },
    commitClientChampion(state, operator) {
      state.company.key_account_manager = operator
    }
  },
  actions: {
    async searchCompany({ commit }, payload) {
      try {
        const response = await api.postSearchCompany(payload)
        return response
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    },
    async editClientChampion({ commit }, data) {
      const response = await api.changeCompanyClientChampion(data)
      return response
    },
    async fetchCompany({ commit }, vatNumber) {
      commit('SET_LOADING', true)
      const response = await api.getCompany(vatNumber)
      if (response.data) commit('SET_COMPANY', response.data)
      commit('SET_LOADING', false)
    },
    async fetchMembers({ commit }, vatNumber) {
      commit('SET_MEMBERS_LOADING', true)
      const response = await api.getCompanyMembers(vatNumber)
      if (response.data) commit('SET_MEMBERS', response.data)
      commit('SET_MEMBERS_LOADING', false)
    },
    async fetchSignatoryMember({ commit }, vatNumber) {
      commit('SET_SIGNATORY_MEMBER_LOADING', true)
      const response = await api.getCompanySignatoryMember(vatNumber)
      commit('SET_SIGNATORY_MEMBER', response.data.signatory_member)
      commit('SET_SIGNATORY_MEMBER_LOADING', false)
    },
    async postClient({ commit }, payload) {
      try {
        const response = await api.registerClient(payload)
        return { uuid: response.data.client_id, message: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    },
    async postMember({ commit }, payload) {
      try {
        const response = await api.addMember(payload)
        return { success: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    },
    async postMemberRoles({ commit }, data) {
      try {
        const response = await api.addMemberRoles(data)
        return { success: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    },
    async postMemberTitle({ commit }, data) {
      try {
        const response = await api.addMemberTitle(data)
        return { success: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    },
    async postMemberPositions({ commit }, data) {
      try {
        const response = await api.addMemberPositions(data)
        return { success: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    },
    async postCompanyEmail({ commit }, data) {
      try {
        const response = await api.changeCompanyEmail(data)
        commit('SET_COMPANY_EMAIL', data.payload.email)
        return { success: response.data.message }
      } catch ({ response }) {
        return { error: response.data.message || response.data.error_type }
      }
    }
  }
}
