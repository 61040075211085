import initialState from '../_helpers/initialState'
import api from '@/services/api_v2'

export default {
  namespaced: true,
  state: {
    ...initialState,
    belongings: []
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_COMPANY_BELONGINGS(state, payload) {
      state.belongings = payload
    }
  },
  actions: {
    async fetchCompanyBelongings({ commit }, vatNumber) {
      commit('SET_LOADING', true)
      const response = await api.getCompany(vatNumber)
      if (response.data) commit('SET_COMPANY_BELONGINGS', response.data.belongings)
      commit('SET_LOADING', false)
    }
  }
}
