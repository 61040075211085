export default {
  forms: {
    add_driving_licence: 'Ajouter un permis de conduire',
    add_identity_card: "Ajouter une carte d'identité",
    contractually_frozen_warning:
      'Ce client est lié à (au moins) un contrat. Veillez à bien encoder les avenants nécessaires correspondants aux modifications.',
    select_options: {
      available_locales: [
        {
          label: 'Français',
          value: 'fr'
        },
        {
          label: 'Néerlandais',
          value: 'nl'
        }
      ],
      civil_statuses: [
        {
          label: 'Célibataire',
          value: 'single'
        },
        {
          label: 'Veuf',
          value: 'widower'
        },
        {
          label: 'Divorcé(e)',
          value: 'divorced'
        },
        {
          label: 'Marié(e)',
          value: 'married'
        },
        {
          label: 'Cohabitant',
          value: 'cohabitant'
        }
      ],
      genders: [
        {
          label: 'Homme',
          value: 'male'
        },
        {
          label: 'Femme',
          value: 'female'
        }
      ],
      owner_titles: [
        {
          label: 'Administrateur',
          value: 'administrator'
        },
        {
          label: 'Indépendant',
          value: 'independent'
        },
        {
          label: 'Gérant',
          value: 'manager'
        }
      ],
      unfit_reasons: [
        {
          label: 'Fiché',
          value: 'on_file'
        },
        {
          label: 'Fiché pour sinistre',
          value: 'claim'
        },
        {
          label: 'Fiché pour impayés',
          value: 'unpaid'
        },
        {
          label: 'Comportement agressif',
          value: 'aggressive'
        },
        {
          label: 'Chronophage',
          value: 'time_consuming'
        }
      ],
      professional_statuses: [
        {
          label: 'Salarié',
          value: 'employee'
        },
        {
          label: 'Indépendant en personne physique',
          value: 'independent'
        },
        {
          label: 'Indépendant en société',
          value: 'independent_company'
        },
        {
          label: 'Fonctionnaire',
          value: 'state_employee'
        },
        {
          label: 'Ouvrier',
          value: 'workman'
        },
        {
          label: 'Retraité',
          value: 'retired'
        },
        {
          label: 'Sans profession',
          value: 'unemployed'
        },
        {
          label: 'Étudiant',
          value: 'student'
        },
        {
          label: 'Autre',
          value: 'other'
        }
      ]
    },
    unfit:
      "Plus d'informations sur le refus <a href='https://www.notion.so/seraphin-be/Acceptation-et-refus-client-c0fc8eeb1b9e4f729596a72bb69f7610?pvs=4' target='_blank'>ici</a>",
    user_locale_form_guidance:
      "Indiquer ici la langue dans laquelle Yago communique avec ce client.<br>Il s'agit également de la langue dans laquelle seront envoyés les emails/sms automatiques.",
    user_locale_form_title: 'Langue du client',
    user_team_form_guidance: "Sélectionnez l'équipe dans laquelle vous souhaitez déplacer le client.",
    user_team_form_title: "Changer l'équipe du client",
    user_team_form_placeholder: 'Sélectionnez une équipe',
    user_no_team: "Ce client n'a pas d'équipe",
    guidance_name_format:
      'Veillez à ne pas mettre de caractères spéciaux (tel que des parenthèses) lorsque vous modifiez le nom du client !',
    detailed_unfit_disclaimer: 'Ce client a été refusé depuis le {unfittedAt} par {unfitOperatorEmail}',
    unfit_disclaimer: 'Ce client a été refusé',
    edit_client_champion: 'Changer de Client Champion',
    edit_client_champion_guidance: 'Sélectionnez un Client Champion de la team: {team}, à attribuer à ce client.',
    no_client_champion: '- Aucun conseiller attribué -',
    add_team_first_guidance: "Veuillez d'abord attribuer une équipe à ce client",
    no_longer_client_champion:
      "⚠ L'opérateur {operator}, qui est attribué à ce client, n'est plus client champion ou ne fait plus partie de l'équipe. Veuillez sélectionner quelqu'un d'autre."
  },
  header: {
    dropdown: {
      chargebee: 'Chargebee',
      pipedrive: 'Pipedrive',
      sendinblue: 'Sendinblue'
    },
    operator_comment: "Note pour l'operateur (privé)",
    tags: {
      gross_premium: 'TOTAL: € {grossPremium}',
      unfit: 'Refusé',
      waiting_for_signature: 'Documents en attente de signature'
    },
    tooltips: {
      waiting_for_signature: 'Les documents peuvent être des contrats, avenants, mandats SEPA, renons ou transferts'
    },
    total_gross_premium: 'Prime totale:',
    user_locale: {
      fr: 'Francophone',
      nl: 'Néerlandophone'
    },
    refused_by: 'Client refusé le {date} par {operator}',
    unfit_reasons: {
      on_file: 'Fiché',
      claim: 'Fiché pour sinistre',
      unpaid: 'Fiché pour impayés',
      aggressive: 'Comportement agressif',
      time_consuming: 'Chronophage'
    },
    pass: 'Passe',
    claimsRequest: 'Obtenir att. sinistralité ou copie contrat pour client'
  },
  journey: {
    aircall_modal: {
      comments: 'Eventuels commentaires:',
      lign_number: 'Nom de la ligne:',
      not_found: 'Non trouvée',
      operator: "Opérateur en charge de l'appel:",
      recording: 'Enregistrement de la conversation Aircall',
      team: 'Team:'
    },
    email_modal: {
      not_found: 'Non trouvé'
    },
    new_message: '💬  Nouveau SMS'
  },
  pending_actions: {
    amendments: 'Avenant | Avenant | Avenants',
    confirmation: 'Voulez-vous vraiment envoyer un mail de rappel à {fullName} ? ',
    contracts: 'Contrat | Contrat | Contrats',
    documents_pending_upload: "- | Document en attente d'upload | Documents en attente d'upload",
    documents_waiting_for_signature: '- | Document en attente de signature | Documents en attente de signature',
    last_reminded_at: 'Dernier rappel envoyé le ',
    last_reminder: 'Le dernier rappel était {date}.',
    licence_plates: "- | Plaque d'immatriculation manquante | Plaques d'immatriculation manquantes",
    life_procedures: 'Procédure Vie | Procédure Vie | Procédures Vie',
    never_sent: 'Aucun rappel envoyé',
    pdf_procedures: 'Procédure PDF | Procédure PDF | Procédures PDF',
    sepa_mandates: 'Mandat SEPA | Mandat SEPA | Mandats SEPA',
    terminations: 'Renon | Renon | Renons',
    title: 'Tâches à effectuer par le client ⚠️',
    transfers: 'Transfert | Transfert | Transferts'
  },
  profile: {
    card_titles: {
      belongings: 'Objets de risque',
      company_info: 'Société',
      financial_data: 'Données financières',
      general_info: 'Infos générales'
    },
    elements: {
      address: 'Addresse:',
      age: 'Age:',
      back: 'Verso',
      bic: 'BIC:',
      chargebee: 'Lien vers Chargebee:',
      date_of_birth: 'Date de naissance:',
      email: 'Mail:',
      front: 'Recto',
      gender: 'Genre:',
      gross_premium: 'Total des primes:',
      iban: 'IBAN:',
      legal_name: 'Dénomination légale:',
      mobile_phone: 'Numéro de mobile:',
      name: 'Nom:',
      owner_title: 'Fonction du client:',
      subscription: 'Souscription',
      vat_number: 'Numéro de TVA:'
    }
  },
  tabs: {
    accident_record: 'Sinistralité',
    contracts: 'Contrats',
    full_package_bundle: 'Offre',
    pdf_procedures: 'Procédures PDF',
    pending_actions: 'Tâches à effectuer',
    profile: 'Profil',
    wins: 'Wins',
    company: 'Entreprises'
  },
  client_from: 'Client depuis ',
  female_client_from: 'Cliente depuis ',
  terminated_from: 'Résilié depuis ',
  female_terminated_from: 'Résiliée depuis ',
  from_in_years: '{years} an(s) et {months} mois',
  from_in_months: '{months} mois et {days} jour(s)',
  from_in_days: '{days} jour(s)',
  missing_datas: "⚠️ D'autres données sont manquantes pour ce client, veuillez les compléter"
}
