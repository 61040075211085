export default {
  copied_to_clipboad: "Le texte de l'e-mail a été copié:",
  copy: 'Copier',
  email_to_copy: 'Email à envoyer ✉️:',
  new_termination: '⛔ Nouveau renon',
  new_termination_date_email_content: `Bonjour,<br>
<br>
Merci de décaler la prise d'effet du contrat {policyNumber} au {policyEndDate}.
<br>Le client {clientFullName} est assuré jusqu'à cette date chez {oldInsurerName}.
<br>
<br>Merci d'avance,`,
  new_termination_date_info:
    "Tu as acté le renon du client à une autre date, ainsi :<br><br>1. Copie le template de mail ci-dessous et envoie-le par mail à la compagnie pour changer la date du NOUVEAU contrat du client (que tu as sûrement déjà mis en place, car c'est le process pour éviter d'oublier d'assurer le client)<br>2. Crées-toi immédiatement une activité Pipedrive pour dans 3 semaines, afin de vérifier à ce moment-là si l'avenant est bien passé, et modifier à ce moment-là la date de prise d'effet du contrat sur Ardor en conséquence",
  title: 'Renons',
  otherInsurer: 'Aucun assureur',
  as: 'en qualité de',
  represented_by: 'représentée par'
}
