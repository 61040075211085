import { SET_LOADING, SET_CONTRACT, WAITING_FOR_SIGNATURE } from './constants'
import { contract } from './services'
import initialState from './initialState'
import api from '@/services/api_v2'
import documents from './documents'

const { VUE_APP_CHARGEBEE_BASE_URL } = import.meta.env

export default {
  namespaced: true,
  modules: {
    documents
  },
  state: {
    ...initialState
  },
  getters: {
    chargebeeLink(state) {
      return `${VUE_APP_CHARGEBEE_BASE_URL}/admin-console/subscriptions/${state.chargebeeSubscriptionId}`
    },
    waitingForSignature(state) {
      return state.status.value === WAITING_FOR_SIGNATURE
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.meta.loading = payload
    },
    [SET_CONTRACT](state, payload) {
      state = Object.assign(state, payload)
    }
  },
  actions: {
    async fetch({ state, commit }, params) {
      commit(SET_LOADING, true)
      const { success, data } = await contract.show(params)
      if (success) {
        commit(SET_CONTRACT, data)
      }
      commit(SET_LOADING, false)
      return { data }
    },
    async send({ state, commit }, data) {
      const { success, message } = await contract.post(data)
      return { success, message }
    },
    async destroy({ state, commit }, data) {
      const { success, message } = await contract.delete(data)
      return { success, message }
    },
    async reinstate({ state, commit }, data) {
      const { success, message } = await contract.reinstate(data)
      return { success, message }
    },
    async rectifyContract({ state, commit }, data) {
      const { success, message } = await contract.reinstate(data)
      return { success, message }
    },
    async transferContract({ state, commit }, data) {
      try {
        const response = await api.postTransferContract(data)
        return { success: true, message: response.data?.message }
      } catch ({ error, response }) {
        return { error: true, message: response.data?.message }
      }
    },
    async save({ state, commit }, { isNew, ...payload }) {
      let action = null
      if (payload.action) {
        action = payload.action
        delete payload.action
      }

      const { success, message, data, error } = await (isNew
        ? contract.create(payload)
        : payload.contract.winUuid || (action && action === 'rectify')
          ? contract.rectify(payload)
          : contract.update(payload))
      if (success) commit(SET_CONTRACT, data)
      return { success, message }
    }
  }
}
