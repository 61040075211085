export default {
  cancel: 'Annuleren',
  email: 'Email',
  email_required: 'E-mail is verplicht',
  first_name: 'Voornaam',
  first_name_required: 'Voornaam is verplicht',
  last_name: 'Naam',
  last_name_required: 'Naam is verplicht',
  mobile_phone: 'Mobiel nummer',
  language: 'Taal',
  language_required: 'Taal is vereist',
  language_options: {
    french: 'Français',
    dutch: 'Dutch'
  },
  new_member: 'Nieuw lid',
  create_member: 'Lid toevoegen',
  member_created: 'Lid toegevoegd',
  member_already_registered: 'Dit lid bestaat al',
  member_not_created: 'Er is een fout opgetreden, het lid kon niet worden toegevoegd'
}
